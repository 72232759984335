/* eslint-disable vars-on-top, no-shadow, @typescript-eslint/no-shadow */
const shorthandVariableRegex = /F(\d+(?:\.\d+)?)/gm;

import { Parser } from 'expr-eval';

interface GPAdvancedCalculationsConfig {
	formId: string;
}

interface FormulaField {
	field_id: number;
	formula: string;
	rounding: string | number;
}

type ParsedFormulaItem = (
	| ParsedFormulaOperatorItem
	| ParsedFormulaSepItem
	| ParsedFormulaFunctionItem
	| ParsedFormulaValueItem
	| ParsedFormulaComparisonOpItem
	| ParsedFormulaLogicalOpItem
) & {
	depth: number;
	processedAtDepth?: number;
	nonFunctionItem?: boolean;
};

interface ParsedFormulaSepItem {
	type: 'sep';
	value: ',';
}

type Operator = '*' | '-' | '+' | '/' | '^' | '%';

interface ParsedFormulaOperatorItem {
	type: 'operator';
	value: Operator;
}

interface ParsedFormulaFunctionItem {
	type: 'function';
	value: string;
}

interface ParsedFormulaValueItem {
	type: 'value';
	value: number | string; // String for merge tags
}

interface ParsedFormulaComparisonOpItem {
	type: 'comparisonOp';
	value: string;
}

interface ParsedFormulaLogicalOpItem {
	type: 'logicalOp';
	value: string;
}

type ParsedFormula = ParsedFormulaItem[];

class GPAdvancedCalculations {
	public formId: number;

	private parser!: Parser;

	/* Used over Math.PI to keep precision consistent between PHP and JS. */
	readonly pi20DecimalPlaces = 3.14159265358979323846;

	constructor({ formId }: GPAdvancedCalculationsConfig) {
		this.formId = parseInt(formId);

		this.init();
	}

	init = () => {
		this.setupParser();

		window.gform.addFilter(
			'gform_calculation_formula',
			(
				formula: string,
				formulaField: FormulaField,
				formId: string,
				calcObj: any
			) => {
				if (parseInt(formId) !== this.formId) {
					return formula;
				}

				formula = this.replaceShorthandVariables(
					formula,
					formulaField,
					this.formId
				);
				formula = this.processConditionals(
					formula,
					formulaField,
					this.formId,
					calcObj
				);
				formula = this.processExponents(
					formula,
					formulaField,
					this.formId,
					calcObj
				);
				formula = this.processFunctions(
					formula,
					formulaField,
					this.formId,
					calcObj
				);

				return formula;
			},
			15
		);

		jQuery(document).trigger('gform_post_conditional_logic');

		this.bindShorthandCalcEvents();
		this.addGPPAListener();
	};

	/**
	 * Sets up the expr-eval parser. It disables certain functionality.
	 */
	setupParser = () => {
		this.parser = new Parser({
			operators: {
				// These default to true, but are included to be explicit
				add: true,
				concatenate: true,
				conditional: true,
				divide: true,
				factorial: true,
				multiply: true,
				power: true,
				remainder: true,
				subtract: true,

				logical: true,
				comparison: true,

				// Disable 'in' and = operators
				in: false,
				assignment: false,
			},
		});

		// Remove all functions.
		this.parser.functions = {};

		// Delete all unary operators outside '-', '+', and '!'
		for (const operator in this.parser.unaryOps) {
			if (['-', '+', '!'].indexOf(operator) === -1) {
				delete this.parser.unaryOps[operator];
			}
		}
	};

	/**
	 * Used for binding the calculation events on fields referenced in formulas using shorthand variables.
	 *
	 * Loosely based on GFCalc.bindCalcEvents
	 */
	bindShorthandCalcEvents = () => {
		// Empty or undefined calculation formula, return early.
		if (!window.gf_global.gfcalc) {
			return;
		}
		const calcObj = window.gf_global.gfcalc[this.formId];
		const { formulaFields, formId } = calcObj;

		for (let i = 0; i < formulaFields.length; i++) {
			const formulaField: FormulaField = jQuery.extend(
				{},
				formulaFields[i]
			);
			const formulaFieldId = formulaField.field_id;

			const matches = formulaField.formula.match(shorthandVariableRegex);

			calcObj.isCalculating[formulaFieldId] = false;

			for (const i in matches) {
				if (!matches.hasOwnProperty(i)) continue;

				// @ts-ignore
				const inputId = matches[i].replace(/^F/, '');
				const fieldId = parseInt(inputId, 10);

				const input = jQuery(`#field_${formId}_${fieldId}`).find(
					['input', 'select', 'textarea']
						.map((tag) => `${tag}[name="input_${inputId}"]`)
						// Checkboxes
						.concat(`input[id^="choice_${formId}_${inputId}_"]`)
						.join(', ')
				);

				const eventNamespace = `gpacShorthand_${formulaFieldId}`;

				// Prevent double-binding
				jQuery(input).off(`.${eventNamespace}`);

				if (
					input.prop('type') === 'checkbox' ||
					input.prop('type') === 'radio'
				) {
					jQuery(input).on(`click.${eventNamespace}`, function() {
						calcObj.bindCalcEvent(inputId, formulaField, formId, 0);
					});
				} else if (
					input.is('select') ||
					input.prop('type') === 'hidden'
				) {
					jQuery(input).on(`change.${eventNamespace}`, function() {
						calcObj.bindCalcEvent(inputId, formulaField, formId, 0);
					});
				} else {
					jQuery(input).on(`change.${eventNamespace}`, function() {
						calcObj.bindCalcEvent(inputId, formulaField, formId, 0);
					});
					jQuery(input).on(`keydown.${eventNamespace}`, function() {
						calcObj.bindCalcEvent(inputId, formulaField, formId);
					});
				}

				// allow users to add custom methods for triggering calculations
				window.gform.doAction(
					'gform_post_calculation_events',
					// @ts-ignore
					[matches[i], inputId],
					formulaField,
					formId,
					calcObj
				);
			}
		}
	};

	/**
	 * Listen for field's being refreshed by GPPA and rebind as needed.
	 */
	addGPPAListener = () => {
		jQuery(document).on('gppa_updated_batch_fields', (event, formId) => {
			// eslint-disable-next-line eqeqeq
			if (this.formId != formId) {
				return;
			}

			this.bindShorthandCalcEvents();
		});
	};

	processConditionals = (
		formula: string,
		formulaField: FormulaField,
		formId: number,
		calcObj: any
	) => {
		// eslint-disable-line no-unused-vars
		let match;

		const regex = /^if\s*\(([\s\S]+?)\)\s*:[\s]+(.+)[\s]+((?:elseif\s*\(.+\)\s*:[\s]+.+[\s]+)*?)else\s*:[\s]+(.+)[\s]+endif\s*;/g,
			elseIfRegex = /elseif\s*\(([\s\S]+?)\)\s*:[\s]+(.+)/g;

		let modified = formula;

		while ((match = regex.exec(formula)) !== null) {
			let elseIfMatch;

			const fullMatch = match[0],
				elseIfs = match[3],
				statements = [];

			statements.push({
				tag: 'if',
				condition: this.replaceMergeTags(
					match[1],
					formulaField,
					formId
				),
				formula: match[2],
			});

			if (elseIfs) {
				while ((elseIfMatch = elseIfRegex.exec(elseIfs)) !== null) {
					statements.push({
						tag: 'elseif',
						condition: this.replaceMergeTags(
							elseIfMatch[1],
							formulaField,
							formId
						),
						formula: elseIfMatch[2],
					});
				}
			}

			statements.push({
				tag: 'else',
				condition: null,
				formula: match[4],
			});

			// @ts-ignore
			jQuery.each(statements, (i, statement) => {
				let condition: string | null = statement.condition;

				if (condition) {
					condition = this.processExponents(
						condition,
						formulaField,
						this.formId,
						calcObj
					);

					condition = this.processFunctions(
						condition,
						formulaField,
						this.formId,
						calcObj
					);
				}

				if (
					(statement.tag === 'else' &&
						statement.condition === null) ||
					!!this.evalFormula(condition!, formulaField.field_id)
				) {
					modified = modified.replace(fullMatch, statement.formula);

					return false;
				}
			});
		}

		return modified;
	};

	replaceShorthandVariables = (
		formula: string,
		formulaField: FormulaField,
		formId: number
	) => {
		// Shorthand Field Variables
		let shorthandVariableMatch;

		while (
			(shorthandVariableMatch = new RegExp(shorthandVariableRegex).exec(
				formula
			)) !== null
		) {
			const [fullMatch, inputId] = shorthandVariableMatch;

			formula = formula.replace(
				/* Use negative lookahead to prevent partial matches. e.g. if replacing F4, don't replace F43. */
				new RegExp(`${fullMatch}(?!\d)`),
				this.replaceMergeTag(inputId, undefined, formulaField, formId)
			);
		}

		return formula;
	};

	replaceMergeTags = (
		formula: string,
		formulaField: FormulaField,
		formId: number
	) => {
		// Regular Field Variable Merge Tags
		const matches = window.GFMergeTag.parseMergeTags(formula);

		for (const i in matches) {
			if (!matches.hasOwnProperty(i)) {
				continue;
			}

			formula = formula.replace(
				matches[i][0],
				this.replaceMergeTag(
					matches[i][1],
					matches[i][3],
					formulaField,
					formId
				)
			);
		}

		return this.replaceShorthandVariables(formula, formulaField, formId);
	};

	replaceMergeTag = (
		inputId: string,
		modifier: string | undefined,
		formulaField: FormulaField,
		formId: number
	) => {
		const fieldId = parseInt(inputId, 10);

		if (typeof modifier === 'undefined') {
			const isProductRadio = jQuery(
				'.gfield_price input[name=input_' + fieldId + ']'
			).is('input[type=radio]');
			const isProductDropdown =
				jQuery('.gfield_price select[name=input_' + fieldId + ']')
					.length > 0;
			const isOptionCheckbox = jQuery(
				'.gfield_price input[name="input_' + inputId + '"]'
			).is('input[type=checkbox]');

			if (isProductDropdown || isProductRadio || isOptionCheckbox) {
				modifier = 'price';
			} else {
				modifier = 'value';
			}
		}

		// If the modifier is "choice_label" (custom to GPAC), remove it as we're preventing :value or :price from being added to the merge tag.
		if (modifier === ':choice_label') {
			modifier = undefined;
		}

		const isNumberField = jQuery(
			'.gfield input[name="input_' + inputId + '"]'
		).closest('.ginput_container_number').length;

		const isVisible = window.gf_check_field_rule
			? window.gf_check_field_rule(formId, fieldId, true, '') === 'show'
			: true;

		let value = isVisible
			? window.GFMergeTag.getMergeTagValue(formId, inputId, modifier)
			: 0;

		// Determine isStringCondition based on the value of the merge tag.
		// We can detect if it's a number or string by checking if it's a number. Numbers include those with decimal comma
		// formatting and currency prefixes or suffixes.
		// The pattern for currencies can be a single currency symbol or a currency symbol followed by a space.
		const currencyConfig = window.gf_global.gf_currency_config;
		const currencySymbol =
			currencyConfig.symbol_left || currencyConfig.symbol_right;
		const decimalSeparator = currencyConfig.decimal_separator;
		const thousandSeparator = currencyConfig.thousand_separator;

		// Create a regex pattern for number with decimal and thousand separators
		const numberPattern = new RegExp(
			`^[0-9\\${decimalSeparator}\\${thousandSeparator}%]+$`
		);

		// Create a regex pattern for currency symbol
		const currencyPattern = new RegExp(
			`^\\${currencySymbol}|\\${currencySymbol} `
		);

		let isValueString;

		if (
			isNumberField ||
			(value !== '' &&
				typeof value === 'string' &&
				(value.match(numberPattern) || value.match(currencyPattern)))
		) {
			isValueString = false;
		} else {
			isValueString = true;
		}

		if (
			/**
			 * Filter whether a number from a merge tag replacement should be cleaned (e.g. commas and currency removed).
			 *
			 * @since 1.0.2
			 *
			 * @param boolean shouldClean 	Whether the number should be cleaned.
			 * @param string  value 			The value from the merge tag replacement.
			 * @param number  formId 			The current form ID.
			 * @param number  fieldId 		The field that the value is being pulled from.
			 * @param object  formulaField 	The current field having its formula evaluated. Contains properties such as `field_id`, `formula`, and `rounding`.
			 */
			window.gform.applyFilters(
				'gpac_should_clean_merge_tag_value',
				!isValueString,
				value,
				formId,
				fieldId,
				formulaField
			) &&
			/*
			 * Make sure `gfcalc` is available as it sometimes isn't available if using the Beaver Builder
			 * editor. See ticket #48623
			 */
			typeof window.gf_global.gfcalc[formId] !== 'undefined'
		) {
			value = window.gf_global.gfcalc[formId].cleanNumber(
				value,
				formId,
				fieldId,
				formulaField
			);
		}

		// If we're in a string condition, we need to safely wrap the value in quotes, escaping any existing quotes.
		if (isValueString) {
			value = JSON.stringify(value);
		}

		return value;
	};

	processExponents = (
		formula: string,
		formulaField: FormulaField,
		formId: number,
		calcObj: any
	) => {
		const items = this.parseFormula(formula),
			processed: ParsedFormula = [];

		while (items.length > 0) {
			const rootItem = items.shift()!;

			if (rootItem.type !== 'operator' || rootItem.value !== '^') {
				processed.push(rootItem);
				continue;
			}

			const targetDepth = rootItem.depth;

			// get the base: dip back into the processed items and find all items that are at the same or greater depth than our target
			const base: ParsedFormula = [];
			while (processed.length > 0) {
				const item = processed.pop()!;
				// accepts a single item at the same depth and only if an item of a greater depth has not already been added
				if (item.depth === targetDepth) {
					if (base.length === 0) {
						base.push(item);
					} else {
						processed.push(item);
					}
					break;
					// accepts multiple items at a greater depth
				} else if (item.depth > targetDepth) {
					base.push(item);
				} else {
					processed.push(item);
					break;
				}
			}
			base.reverse();

			// get the power: only the first item at target depth or all items below target depth
			const pow: ParsedFormula = [];
			while (items.length > 0) {
				const item = items.shift()!; // eslint-disable-line no-redeclare
				// accepts a single item at the same depth and only if an item of a greater depth has not already been added
				if (item.depth === targetDepth) {
					if (pow.length === 0) {
						pow.push(item);
					} else {
						items.unshift(item);
					}
					break;
					// accepts multiple items at a greater depth
				} else if (item.depth > targetDepth) {
					pow.push(item);
					// if item is at a lesser depth, we're done collecting the pow. Add the current item back to the queue
					// so the outer while loop and loop for any other exponents.
				} else {
					items.unshift(item);
					break;
				}
			}

			const baseEvaled = this.evalFormula(
				this.processFunctions(
					calcObj.replaceFieldTags(
						formId,
						this.buildFormula(base),
						formulaField
					),
					formulaField,
					formId,
					calcObj
				),
				formulaField.field_id
			);

			const powEvaled = this.evalFormula(
				this.processFunctions(
					calcObj.replaceFieldTags(
						formId,
						this.buildFormula(pow),
						formulaField
					),
					formulaField,
					formId,
					calcObj
				),
				formulaField.field_id
			);

			processed.push({
				...rootItem,
				type: 'value',
				value: Math.pow(baseEvaled, powEvaled),
			});
		}

		formula = this.buildFormula(processed);

		return formula;
	};

	evalFormula = (formula: string, fieldId?: number) => {
		try {
			formula = formula.trim();

			// Replace && and || with "and" and "or" respectively.
			formula = formula.replace(/&&/g, 'and').replace(/\|\|/g, 'or');

			const expr = this.parser.parse(formula);
			return expr.evaluate();
		} catch (e) {
			const warning = fieldId
				? `GP Advanced Calculations: Could not process formula in field ID ${fieldId}. Formula: ${formula}`
				: `GP Advanced Calculations: Could not process formula. Formula: ${formula}`;

			// eslint-disable-next-line no-console
			console.warn(warning, e);
		}

		return 0;
	};

	processFunctions = (
		formula: string,
		formulaField: FormulaField,
		formId: number,
		calcObj: any
	) => {
		let items: ParsedFormula = this.parseFormula(formula);

		// Short-circuit if there are no functions.
		if (!items.some((item) => item.type === 'function')) {
			return this.buildFormula(items);
		}

		let functions: {
			[name: string]: (...args: any[]) => number;
		} = {
			abs: Math.abs,
			average() {
				return (
					Array.prototype.slice
						// eslint-disable-next-line prefer-rest-params
						.call(arguments)
						.reduce(function(a, b) {
							return a + b;
						}, 0) / arguments.length
				);
			},
			ceil: Math.ceil,
			exp: Math.exp,
			floor: Math.floor,
			fv(
				rate: number,
				numberOfPeriods: number,
				paymentAmount: number,
				presentValue: number,
				endOrBeginning: 0 | 1
			) {
				if (typeof endOrBeginning === 'undefined') {
					endOrBeginning = 0;
				}

				// Credit https://stackoverflow.com/a/17195572
				const pow = Math.pow(1 + rate, numberOfPeriods);
				let fv;

				if (rate) {
					// eslint-disable-next-line no-mixed-operators
					fv =
						(paymentAmount *
							(1 + rate * endOrBeginning) *
							(1 - pow)) /
							rate -
						presentValue * pow;
				} else {
					// eslint-disable-next-line no-mixed-operators
					fv = -1 * (presentValue + paymentAmount * numberOfPeriods);
				}

				return fv;
			},
			ln: Math.log,
			log: Math.log10,
			max: Math.max,
			min: Math.min,
			pi: () => this.pi20DecimalPlaces,
			round: Math.round,
			sqrt: Math.sqrt,
			sin: Math.sin,
			cos: Math.cos,
			tan: Math.tan,
			asin: Math.asin,
			acos: Math.acos,
			atan: Math.atan,
		};

		/**
		 * Filter the functions list used by GP Advanced Calculations on the frontend (when filling out the form). This
		 * hook needs to be used in conjunction with the `gpac_functions` PHP hook on the backend.
		 *
		 * @since 1.0.21
		 *
		 * @param {Object} functions The functions list. The key is the function name and the value is a callable.
		 */
		functions = window.gform.applyFilters('gpac_functions', functions);

		// Find the highest depth in the remaining items.
		let targetDepth = Math.max(...items.map((item) => item.depth));

		/*
		 * Work through the formula by highest depth and working our way down to the root.
		 *
		 * Find groups of items that are at the same depth and process them as a group and remove them from items.
		 */
		itemWhile: while (
			!items.every((item) => item.processedAtDepth !== undefined)
		) {
			if (targetDepth < 0) {
				break;
			}

			// Find items at the target depth and stop when we find an item not in the current group.
			const groupItems: ParsedFormula = [];
			let indexToInsertResultAt!: number;

			for (let i = 0; i < items.length; i++) {
				const item = items?.[i];

				// Skip items deleted while inside the loop
				if (typeof item === 'undefined' || item === null) {
					continue;
				}

				// Use GTE here in case there were a lot of parentheses and some items with inflated depths.
				if (
					item.depth >= targetDepth &&
					(!item.processedAtDepth ||
						item.processedAtDepth !== targetDepth)
				) {
					groupItems.push(item);
					(items as any)[i] = null;

					if (!indexToInsertResultAt) {
						indexToInsertResultAt = i;
					}
				} else if (groupItems.length) {
					// Break out of the for loop if we find an item that is not in the current group.
					break;
				}
			}

			// No items found at this depth, reduce target depth and start at the top of the loop again.
			if (!groupItems.length) {
				targetDepth--;
				continue;
			}

			/*
			 * If there are is a function present, we need to split up the group into chunks and handle each.
			 */
			if (groupItems.some((item) => item.type === 'function')) {
				const subGroups: ParsedFormula[] = [[]];

				for (const groupItem of groupItems) {
					/*
					 * If the current item is a function and matches the current depth, create a new subgroup, so we can
					 * add arguments to the function in the next iterations of this for loop.
					 */
					if (
						groupItem.type === 'function' &&
						groupItem.depth === targetDepth
					) {
						subGroups.push([]);
						subGroups[subGroups.length - 1].push(groupItem);
						continue;
					}

					// Function arguments are at a depth of at least 1 greater than the function.
					if (groupItem.depth > targetDepth) {
						subGroups[subGroups.length - 1].push({
							...groupItem,
							nonFunctionItem: false,
						});
					} else {
						/*
						 * If the last subgroup contains one item, it is a function without params, and we need to
						 * create a new subgroup.
						 */
						if (subGroups[subGroups.length - 1].length === 1) {
							subGroups.push([]);
						}

						/*
						 * If the last subgroup item depth is greater than the current item depth, we need to create a
						 * new subgroup as we've likely encountered an operator following a function.
						 */
						if (
							subGroups[subGroups.length - 1].length &&
							subGroups[subGroups.length - 1][
								subGroups[subGroups.length - 1].length - 1
							].depth > groupItem.depth
						) {
							subGroups.push([]);
						}

						/*
						 * Items that are not part of a function need to be added into their own subgroup but with a
						 * flag to not touch them. We put them into a subgroup to re-add to items without losing their
						 * order.
						 */
						subGroups[subGroups.length - 1].push({
							...groupItem,
							nonFunctionItem: true,
							processedAtDepth: targetDepth,
						});
					}
				}

				// Create a new array to hold the results of the subgroups. This will be spliced back into items.
				const subGroupItems: ParsedFormula = [];

				for (const subGroup of subGroups) {
					if (!subGroup.length) {
						continue;
					}

					/*
					 * If the subgroup contains non function items, just queue the subgroup items to be added back
					 * into items.
					 */
					if (subGroup.some((item) => item.nonFunctionItem)) {
						subGroupItems.push(...subGroup);
						continue;
					}

					const functionName = subGroup[0].value
						.toString()
						.toLowerCase();

					const functionArgs = subGroup
						.slice(1)
						.map((item) =>
							item.type === 'value'
								? calcObj.replaceFieldTags(
										formId,
										item.value,
										formulaField
								  )
								: null
						)
						.filter((item) => item !== null)
						.map(parseFloat);

					if (typeof functions[functionName] === 'function') {
						subGroupItems.push({
							processedAtDepth: targetDepth,
							depth: targetDepth,
							type: 'value',
							value: functions[functionName](...functionArgs),
						});
					}
				}

				items.splice(indexToInsertResultAt, 0, ...subGroupItems);

				items = items.filter((item) => item !== null);

				continue;
			}

			/*
			 * If there aren't any operators in this group, there is nothing to do at this point in the loop as
			 * functions will have already been processed and the only thing left are handling operators.
			 *
			 * Just mark the items as processed and re-add them to the items array, so they're handled at lower depths.
			 */
			if (!groupItems.some((item) => item.type === 'operator')) {
				// Re-add the items that were removed and mark them as processed.
				groupItems.forEach((item) => {
					item.processedAtDepth = targetDepth;
				});

				items.splice(indexToInsertResultAt, 0, ...groupItems);

				items = items.filter((item) => item !== null);

				continue itemWhile;
			}

			/*
			 * At this point, we should have a mixture of values and operators that need their merge tags replaced
			 * and the results evaluated.
			 *
			 * We will create subgroups for each much like we do with functions. If there are no separators, then
			 * we will only have one subgroup.
			 */
			const subGroupsSeps: ParsedFormula[] = [[]];

			for (const groupItem of groupItems) {
				if (groupItem.type === 'sep') {
					subGroupsSeps.push([]);
				} else {
					subGroupsSeps[subGroupsSeps.length - 1].push(groupItem);
				}
			}

			const subGroupSepsItems: ParsedFormula = [];

			for (const subGroup of subGroupsSeps) {
				const contained = calcObj
					.replaceFieldTags(
						formId,
						this.buildFormula(
							JSON.parse(JSON.stringify(subGroup)), // Break reference to objects
							targetDepth
						),
						formulaField
					)
					.trim();

				subGroupSepsItems.push({
					depth: targetDepth,
					processedAtDepth: targetDepth,
					type: 'value',
					value: this.evalFormula(contained, formulaField.field_id),
				});
			}

			items.splice(indexToInsertResultAt, 0, ...subGroupSepsItems);

			items = items.filter((item) => item !== null);
		}

		formula = this.buildFormula(items);

		return formula;
	};

	/**
	 * Moves the pointer forward when parsing the formula until the end of a specified pattern.
	 *
	 * char is always returned even if there's no match.
	 *
	 * @param formula The formula being parsed.
	 * @param char    The current character being read. Can be thought of as the pointer.
	 * @param pattern The RegExp to find the end of the match.
	 */
	matchUntil = (
		formula: string,
		char: string,
		pattern: RegExp
	): { formula: string; value: string } => {
		let lastLetter: any = formula.match(pattern);
		let value: string;

		if (lastLetter) {
			lastLetter = lastLetter[0].length;
			value = char + formula.substr(0, lastLetter);
			formula = formula.substr(lastLetter);
		} else {
			value = char;
		}

		return { formula, value };
	};

	parseFormula = (formula: string): ParsedFormula => {
		formula = formula.replace('/s+/g', ' ').trim();

		const items: ParsedFormula = [];
		let depth = 0;

		while (formula.length > 0) {
			const char = formula.substr(0, 1);

			let item: ParsedFormulaItem,
				value = '';

			let matchResult: { formula: string; value: string };

			formula = formula.substr(1);

			switch (true) {
				case char === '(':
					depth++;
					break;
				case char === ')':
					depth--;
					break;
				case jQuery.inArray(char, ['*', '-', '+', '/', '^', '%']) !==
					-1:
					item = {
						depth,
						type: 'operator',
						value: char as Operator,
					};
					break;
				case char === '{':
					const closingBrace = formula.indexOf('}');
					value = char + formula.substr(0, closingBrace + 1);
					formula = formula.substr(closingBrace + 1);
					item = {
						depth,
						type: 'value',
						value,
					};
					break;
				case char === ',':
					item = {
						depth,
						type: 'sep',
						value: ',',
					};
					break;
				case char === '.':
				case jQuery.isNumeric(char):
					matchResult = this.matchUntil(formula, char, /^[0-9.]+/); // Includes decimals; could be stricter.

					formula = matchResult.formula;
					value = matchResult.value;

					item = {
						depth,
						type: 'value',
						value,
					};
					break;
				case char === '"':
				case char === "'":
					matchResult =
						char === '"'
							? this.matchUntil(formula, char, /[^\\]*?"/)
							: this.matchUntil(formula, char, /[^\\]*?'/);

					formula = matchResult.formula;
					value = matchResult.value;

					item = {
						depth,
						type: 'value',
						value,
					};
					break;
				case char.match(/[a-zA-Z]/i) &&
					char.match(/[a-zA-Z]/i)!.length > 0:
					matchResult = this.matchUntil(formula, char, /^[a-zA-Z]+/i);

					formula = matchResult.formula;
					value = matchResult.value;

					item = {
						depth,
						type: 'function',
						value,
					};
					break;
				case (char.match(/[<>!=]/)?.length ?? 0) > 0:
					matchResult = this.matchUntil(formula, char, /^=+/);

					formula = matchResult.formula;
					value = matchResult.value;

					item = {
						depth,
						type: 'comparisonOp',
						value: ' ' + value + ' ',
					};
					break;
				case (char.match(/[&|]/)?.length ?? 0) > 0:
					matchResult = this.matchUntil(formula, char, /^[&|]+/);

					formula = matchResult.formula;
					value = matchResult.value;

					/* char is always returned in matchUntil(). We can't do anything with a single & or | so we throw it out. */
					if (value.length === 1) {
						continue;
					}

					item = {
						depth,
						type: 'logicalOp',
						value: ' ' + value + ' ',
					};
					break;
			}

			if (item! && item?.value) {
				items.push(item);
			}
		}

		return items;
	};

	/**
	 * @param {ParsedFormula} parsedFormula
	 * @param {number}        startingDepth Specify the starting depth to avoid building a formula with extraneous parentheses
	 */
	buildFormula = (parsedFormula: ParsedFormula, startingDepth = 0) => {
		let depth = 0,
			formula = '';

		for (let i = 0; i < parsedFormula.length; i++) {
			const item = parsedFormula[i];

			/*
			 * Remove starting depth from the item. For instance, if we're taking only items from inside a
			 * function call, we give it the starting depth of the function to avoid building a formula that is wrapped
			 * in numerous parentheses.
			 */
			item.depth -= startingDepth;

			if (depth < item.depth) {
				formula += '('.repeat(item.depth - depth);
				depth = item.depth;
			} else if (depth > item.depth) {
				formula += ')'.repeat(depth - item.depth);
				depth = item.depth;
			}

			formula += item.value;
		}

		if (depth > 0) {
			formula += ')'.repeat(depth);
		}

		return formula;
	};
}

window.GPAdvancedCalculations = GPAdvancedCalculations;

String.prototype.repeat = function(num) {
	// @ts-ignore
	return new Array(num + 1).join(this);
};
